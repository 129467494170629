.l-news {
  &-meta {
    font-weight: 700;
    padding-bottom: 20px;

    p {
      margin: 0;
    }
  }

  &-main {
    padding-bottom: 37px;

    @include bp(md) {
      padding-bottom: 117px;
    }
  }

  &-top {
    @include bp(md) {
      @include display(flex);
      @include flex-direction(row-reverse);
      @include justify-content(space-between);
    }
  }
}

.news-title {
  @include bp(md) {
    max-width: 471px;
    width: 100%;
  }

  p {
    margin: 0;
  }
}
