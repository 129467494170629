.l-approach {
  //background: repeating-linear-gradient( 45deg, #f4f3ef, #f4f3ef 4px, #efeee9 4px, #efeee9 23px );
  background: url("../images/lines.png") #efeee9 repeat;
  background-size: cover;
  padding: 50px 34px 90px;
  @include bp(md) {
    padding-bottom: 110px;
  }
}

.l-approach-top {
  max-width: 771px;
  margin: 0 auto;

  .approach-subheading {
    @include display(flex);
    @include align-items(center);
    margin-bottom: 25px;
  }

  hr {
    margin-top: 0;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $charcoal;
    @include flex(1);
  }

  .field-name-field-approach-subheading {
    padding-left: 7px;
    padding-right: 7px;
    @include flex(2);
    margin-top: -10px;
  }

  .field-name-field-approach-text {
    text-align: center;
    font-size: 0.938rem;
    padding-bottom: 30px;

    p {
      margin-top: 0;
      @include bp(sm) {
        font-size: 0.8125rem;
      }
    }
  }

  .approach-learn-more-link {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.063rem;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .l-arrow-extendable {
      margin-top: 10px;
    }

    a {
      &:hover {
        text-decoration: none;
        @include bp(md) {
          .arrow-extendable {
            @include animation(arrow-rise .3s);
            @include animation-fill-mode(forwards);
            @include animation-timing-function(ease-out);
          }
        }
      }
    }
  }
}

.approach-callout {
  background-color: #fff;
  margin-bottom: 48px;
  text-align: center;
  position: relative;
  width: 100%;
  @include bp(md) {
    margin-bottom: 0;
    margin-right: 30px;

    &:hover {
      .border {
        &-b,
        &-l,
        &-r,
        &-t {
          background-color: $teal;
          position: absolute;
          @include animation-timing-function(ease-out);
        }

        &-l,
        &-r {
          width: 2px;
          height: 0;
        }

        &-b,
        &-t {
          left: 0;
          height: 2px;
          width: 0;
        }

        &-t {
          top: 0;
          @include animation(t_sides .250s);
          @include animation-delay(.500s);
          @include animation-fill-mode(forwards);
        }

        &-b {
          bottom: 0;
          @include animation(b_sides .250s);
          @include animation-delay(.500s);
          @include animation-fill-mode(forwards);
        }

        &-l {
          left: 0;
          bottom: 0;
          @include animation(l_sides .250s);
          @include animation-fill-mode(forwards);
        }

        &-r {
          right: 0;
          top: 0;
          @include animation(r_sides .250s);
          @include animation-delay(.750s);
          @include animation-fill-mode(forwards);
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
    @include bp(md) {
      margin-right: 0;
    }
  }

  &-staging {
    padding: 22px 32px 30px;
    @include bp(md) {
      padding-bottom: 20px;
      height: 191px;
    }
  }

  .field-name-field-approach-callout-1-title,
  .field-name-field-approach-callout-2-title {
    font-weight: 700;
    font-family: $roboto-slab;
    @include bp(md) {
      font-size: 1.125rem;
    }
  }

  .field-name-field-approach-callout-1-link,
  .field-name-field-approach-callout-2-link {
    &:hover {
      @include bp(md) {
        &:before {
          display: block;
          pointer-events: none;
        }
      }
    }
    @include bp(md) {
      &:before {
        content: "";
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        display: none;
        //  border: 2px solid $teal;
      }
    }

    a {
      display: block;
      background-color: $teal;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 24px;
      color: #FFF;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.l-approach-bottom {
  @include bp(md) {
     @include display(flex);
    max-width: 972px;
    margin: 0 auto;
  }
}
