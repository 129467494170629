header {
  background-color: $darkest-blue;

  ul {
    padding-left: 0;

    li.leaf {
      list-style-image: none;
      list-style-type: none;
    }
  }
}

.l {
  &-page-top {
    position: relative;
    z-index: 300;
  }

  &-header {
    @include max-layout();
    position: relative;
    height: 94px;
    background-color: $darkest-blue;
    z-index: 300;

    @include bp(md) {
      height: auto;
    }
  }

  &-logo {
    max-width: 190px;
    width: 100%;

    @include bp(md) {
      max-width: 330px;
    }

    img {
      max-width: 190px;
      width: 100%;

      @include bp(md) {
        max-width: 330px;
      }
    }
  }

  &-logo-secondary {
    display: none;
    position: absolute;
    top: 39px;
    left: 400px;
    width: 94px;
    height: 83px;
    opacity: .15;

    @include bp(md2) {
      display: block;
    }
  }

  &-top-nav {
    @include display(flex);
    @include justify-content(space-between);
    padding-bottom: 14px;
    padding-top: 26px;

    @include bp(md) {
      padding-top: 30px;
    }
  }

  &-social-links {
    ul {
      @include display(flex);
      @include justify-content(flex-end);
      margin-bottom: 0;

      li {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    a {
      display: inline-block;
      text-indent: -5000px;
    }

    .icon {
      &-twitter {
        width: 19px;
        height: 16px;

        &:hover {
          &:before {
            background: url(#{$path-images}icon-twitter-hover.svg) no-repeat;
          }
        }

        &:before {
          content: '';
          background: url(#{$path-images}icon-twitter.svg) no-repeat;
          width: 19px;
          height: 16px;
          display: block;
          position: absolute;
        }
      }

      &-instagram {
        width: 19px;
        height: 19px;

        &:hover {
          &:before {
            background: url(#{$path-images}icon-instagram-hover.svg) no-repeat;
          }
        }

        &:before {
          content: '';
          background: url(#{$path-images}icon-instagram.svg) no-repeat;
          width: 19px;
          height: 19px;
          display: block;
          position: absolute;
        }
      }

      &-facebook {
        width: 9px;
        height: 19px;

        &:hover {
          &:before {
            background: url(#{$path-images}icon-fb-hover.svg) no-repeat;
          }
        }

        &:before {
          content: '';
          background: url(#{$path-images}icon-fb.svg) no-repeat;
          width: 9px;
          height: 19px;
          display: block;
          position: absolute;
        }
      }
    }
  }

  &-primary-nav {
    display: none;

    @include bp(md) {
      display: block;
    }
    a {
      color: #FFF;
      text-decoration: none;
      font-family: $open-sans;
    }
  }

  &-primary-nav-block {
    li.leaf.last {
      padding: 0;
      margin: 0;

      a:hover {
        background-color: transparent;
      }
    }

    .icon-search {
      display: inline-block;
      text-indent: -5000px;
      position: relative;
      width: 13px;
      height: 18px;
      padding: 6px 0 0 0;


      &:before {
        content: '';
        position: absolute;
        width: 13px;
        height: 18px;
        display: block;
        background: url(#{$path-images}icon-magnify.svg) no-repeat;
      }
    }

    a {
      font-size: .8125rem;
      font-weight: 700;
      padding: 10px 12px 10px 12px;
      @include transition(background-color .3s ease);

      &.active {
        color: #FFF;
      }

      &:hover {
        background-color: $dark-blue;
      }
    }
    ul {
      @include display(flex);
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}

.l-nav-top-desktop {
  background-color: $teal;
  position: relative;
  top: 20px;
  display: none;

  @include bp(md) {
    display: block;
  }

  .menu__level1__list-item {
    border-right: 1px solid #FFF;

    &:last-child {
      border-right: 0;
    }
  }

  li {
    .link-container {
      height: 100%;
      font-weight: bold;
    }

    &:focus,
    &:focus-within,
    &:hover {
      > .sub-menu {
        display: block;
      }
    }
  }

  .sub-menu {
    display: none;
  }

  ul {
    height: 43px;
  }

  a {
    text-decoration: none;
    color: #FFF;
    font-family: $roboto-slab;
    text-transform: uppercase;
    font-size: 1.125rem;

    &.active {
      color: #FFF;
    }
  }

  ul {
    @include display(flex);
    margin: 0;

    li.expanded {
      list-style: none;
      list-style-image: none;
      margin: 0;
      padding: 0;
    }

    li.leaf {
      background-color: #FFF;
      border-bottom: 1px solid $light-gray;
      border-left: 1px solid $light-gray;
      border-right: 1px solid $light-gray;
      margin: 0;
      padding: 0;
      height: 50px;

      &.first {
        &:before {
          position: absolute;
          top: -7px;
          left: 48%;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 7px 7px 7px;
          border-color: transparent transparent #FFFFFF transparent;
        }
      }

      a {
        color: #262626;
        font-family: $open-sans;
        font-weight: 700;
        font-size: .8125rem;
        background-color: #FFF;
        padding-top: 14px;
        padding-bottom: 17px;
        display: block;
        @include transition(color .3s ease);

        &:focus,
        &:hover {
          color: $teal;
        }
      }
    }

    > li {
      @include flex(1);
      text-align: center;

      &:focus,
      &:focus-within,
      &:hover {
        ul {
          display: block;
          z-index: 1;
          position: relative;
        }
      }

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding-top: 13px;
      }
    }

    > li > ul {
      display: none;
      height: auto;
    }
  }
}


a.active-trail.menu__link.menu__level1__link {
    padding-top: 13px;
}
