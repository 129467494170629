.page-search {
  .form-item-keys {
    > label {
      display: block;
    }
  }

  input[type="text"] {
    height: 38px;
    width: 75%;
  }

  .search-results {
    padding-left: 0;
    margin-top: 35px;

    .title {
      font-weight: 700;
      font-family: $open-sans;
      font-size: 1.25rem;
      @include bp(md) {
        font-size: 0.9375rem;
      }

      a {
        color: $charcoal;
      }
    }

    li {
      margin-bottom: 40px;
      @include bp(md) {
        margin-bottom: 30px;
      }
    }
  }

  .search-snippet-info {
    padding-left: 0;
  }

  .search-info {
    display: none;
  }
}


.component-heading {
  h2 {
    display: none !important;
  }
}
