@mixin bp($point) {
   @if $point == xxs{
    @media (min-width: 400px) {
       @content;
    }
  }
  @else if $point == xs{
   @media (min-width: 450px) {
      @content;
   }
 }
  @else if $point == xs2 {
     @media (min-width: 550px){
      @content;
    }
  }
  @else if $point == xs3 {
     @media (min-width: 600px){
      @content;
    }
  }
  @else if $point == sm {
     @media (min-width: 768px){
      @content;
    }
  }
  @else if $point == md {
     @media (min-width: 850px){
      @content;
    }
  }

  @else if $point == md1 {
     @media (min-width: 876px){
      @content;
    }
  }

  @else if $point == md2 {
     @media (min-width: 925px){
      @content;
    }
  }
  @else if $point == lg {
     @media (min-width: 1000px){
      @content;
    }
  }
  @else if $point == lg1 {
     @media (min-width: 1160px){
      @content;
    }
  }
  @else if $point == lg2 {
     @media (min-width: 1310px){
      @content;
    }
  }
}
// Layout mixins
@mixin banner-layout() {
  //max-width: 1440px;
  margin: 0 auto;
  padding-left: 35px;
  padding-right: 35px;
  @include bp(md) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@mixin max-layout() {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 35px;
  padding-right: 35px;

  @include bp(md) {
    padding-left: 42px;
    padding-right: 42px;
  }
}
@mixin body-layout() {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 32px;
  @include bp(md) {
    padding-left: 125px;
    padding-right: 125px;
  }
}
@mixin basic-page-layout() {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 34px;
  padding-right: 34px;
}
@mixin single-column() {
  max-width: 975px;
  padding-right: 278px;
  padding-left: 30px;
  float: right;
}
@mixin reset-ul() {
  list-style: none;
  margin: 0;
  padding: 0;
}
@mixin reset-li() {
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
@mixin padding-reusable-component() {
  margin-bottom: 40px;
  @include bp(md) {
    margin-bottom: 50px;
  }
}
@mixin button() {
  font-weight: 700;
  color: white;
  background-color: $red;
  border: 0;
  padding-top: 9px;
  padding-bottom: 11px;
  font-size: 0.813rem;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0 0 0 -5px;
  line-height: 18px;
  width: 90px;
  font-family: "Open Sans", sans-serif;
}
@mixin swipe-fill-right($bg-color, $text-color) {
   @include bp(md){
    position: relative;
    z-index: 0;
    @include transition(color .3s ease);

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $bg-color;
      @include transform(scaleX(0));
      @include transform-origin(0 50%);
      @include transition(transform .3s ease-out);
    }

    &:hover {
      color: $text-color;
      text-decoration: none;

      &:before {
        @include transform(scaleX(1));
      }
    }
  }
}

@mixin reset-button {
  display: flex;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
