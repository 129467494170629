.l-homepage-banner {
  background-color: #000;

  @include banner-layout();
  padding-left: 0;
  padding-right: 0;
  position: relative;
  overflow: hidden;



  .field-name-field-banner-image,
  .field-name-field-mobile-banner-image {
    position: relative;

    img {
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
    	top: 0;
    	bottom: 0;
    	left: 0;
      background: linear-gradient(to right, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 100%);
      opacity: 1;
      width: 60%;
      max-width: 778px;
    }
  }
}

.l-homepage-banner-stage {
  position: absolute;
  top: 0;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 70px;
  max-width: 550px;

  //resetting annoying p styling from drupal
  p {
    margin: 0;
  }

  @include bp(xs3){
    padding-top: 0;
    top: 25%;
  }

  // @include bp(xs3){
  //   padding-top: 0;
  //   top: 57%;
  // }


  @include bp(md) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    top: 36%;
    left: 132px;
  }


  .field-name-field-banner-headline  {
    padding-bottom: 36px;

    @include bp(xs3) {
      padding-bottom: 42px;
    }

    @include bp(md) {
      padding-bottom: 42px;
    }

    p, h2 {
      font-family: $roboto-slab;
      color: #FFF;
      font-size: 1.313rem;
      line-height: 28px;
      font-weight: normal;

      @include bp(xs3) {
        font-size: 2rem;
        line-height: 44px;
      }

      @include bp(md) {
        font-size: 2rem;
        line-height: 44px;
      }
    }
  }

  .field-name-field-banner-button-link {
    text-align: center;

    @include bp(md) {
      text-align: left;
    }

    a {
      color: #FFF;
      font-size: .813rem;
      font-weight: 700;
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
      text-transform: uppercase;
      border: 1px solid #FFF;
      @include swipe-fill-right(#ffffff, $charcoal);

      @include bp(md) {
        display: inline;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 42px;
        padding-right: 42px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
