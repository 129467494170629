footer {
  background-color: $darkest-blue;

  .l-primary-nav-block {
    font-family: $open-sans;
    font-size: 1.250rem;
    padding-bottom: 20px;

    a {
      padding-left: 0;
      font-size: 1.250rem;
      font-weight: 300;
      @include bp(xs2) {
        font-size: 0.813rem;
        padding: 10px 12px;
        margin-right: -12px;
      }

      &.icon-search {
        display: none;
      }
    }

    ul {
      @include reset-ul();

      &.menu {
        @include flex-direction(column);
      }
      @include bp(xs2) {
        text-align: right;
      }
    }

    li {
      @include reset-li();

      &.leaf {
        @include reset-li();
        margin-bottom: 40px;
        @include bp(xs2) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.l {
  &-footer {
    @include body-layout();
    padding-top: 60px;
    padding-bottom: 42px;
    color: #FFF;

    a {
      color: #FFF;
      text-decoration: none;
      overflow-wrap: none;
      word-wrap: none;
      -ms-word-wrap: none;
      /* Adds a hyphen where the word breaks */
      -webkit-hyphens: none;
      -ms-hyphens: none;
      -moz-hyphens: none;
      hyphens: none;
    }
  }

  &-penn-shield {
    text-align: left;
    padding-bottom: 24px;

    img {
      margin: 0;
    }
  }

  &-copyright-info {
    font-family: $open-sans;
    font-size: 0.938rem;
    @include bp(xs2) {
      font-size: 0.813rem;
    }
  }

  &-contact-info {
    font-family: $open-sans;
    font-size: 0.938rem;
    padding-bottom: 40px;
    @include bp(xs2) {
      font-size: 0.813rem;
      padding-bottom: 24px;
    }
  }

  &-footers-boundary {
    @include bp(xs2) {
       @include display(flex);
      @include justify-content(space-between);
    }
  }

  &-footer-secondary-nav {
    font-family: $roboto-slab;
    font-size: 1.250rem;
    font-weight: 700;

    a {
      @include bp(xs2) {
        padding: 10px 12px;
        margin-right: -12px;
      }

      &:hover {
        @include bp(xs2) {
          background-color: $dark-blue;
          @include transition(background-color .3s ease);
        }
      }

      &.active {
        color: #fff;
        @include bp(xs2) {
          background-color: $dark-blue;
          @include transition(background-color .3s ease);
        }
      }
    }
    @include bp(xs2) {
      font-size: 0.938rem;
    }

    ul {
      @include reset-ul();
      @include bp(xs2) {
        text-align: right;
      }
    }

    li {
      @include reset-li();

      &.leaf {
        @include reset-li();
        margin-bottom: 30px;
        @include bp(xs2) {
          margin-bottom: 24px;
        }
      }
    }
  }

  &-footer-social-links {
    padding-bottom: 50px;

    ul {
      @include reset-ul();

      &.menu {
        @include display(flex);
      }
    }

    li {
      @include reset-li();

      &.leaf {
        @include reset-li();
        margin-bottom: 30px;
        padding-right: 50px;
        @include bp(xs2) {
          padding-right: 20px;
        }
      }
    }

    a {
      display: inline-block;
      text-indent: -5000px;
    }

    .icon {
      &-twitter {
        width: 40px;
        height: 33px;
        @include bp(xs2) {
          width: 20px;
          height: 17px;
        }

        &:hover {
          &:before {
            background: url("#{$path-images}icon-twitter-hover.svg") no-repeat;
            background-size: cover;
          }
        }

        &:before {
          content: "";
          background: url("#{$path-images}icon-twitter.svg") no-repeat;
          background-size: cover;
          width: 40px;
          height: 33px;
          display: block;
          position: absolute;
          @include bp(xs2) {
            width: 20px;
            height: 17px;
          }
        }
      }

      &-instagram {
        width: 40px;
        height: 40px;
        @include bp(xs2) {
          width: 20px;
          height: 20px;
        }

        &:hover {
          &:before {
            background: url("#{$path-images}icon-instagram-hover.svg") no-repeat;
            background-size: cover;
          }
        }

        &:before {
          content: "";
          background: url("#{$path-images}icon-instagram.svg") no-repeat;
          background-size: cover;
          width: 40px;
          height: 40px;
          display: block;
          position: absolute;
          @include bp(xs2) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &-facebook {
        width: 19px;
        height: 40px;
        @include bp(xs2) {
          width: 10px;
          height: 20px;
        }

        &:hover {
          &:before {
            background: url("#{$path-images}icon-fb-hover.svg") no-repeat;
            background-size: cover;
          }
        }

        &:before {
          content: "";
          background: url("#{$path-images}icon-fb.svg") no-repeat;
          background-size: cover;
          width: 19px;
          height: 40px;
          display: block;
          position: absolute;
          @include bp(xs2) {
            width: 10px;
            height: 20px;
          }
        }
      }
    }
  }
}
