.node-course-group {
	$teaser-selector: &;
	padding: 30px 60px 15px 30px;
	color: $charcoal;
	background: $lightest-gray;
	&__title {
		margin-bottom: 0;
		font-family: $open-sans;
		font-size: 22px;
	}
	&__bundle {
		display: flex;
		flex-wrap: wrap;
	}
	&__department {
		display: inline-flex;
		flex-wrap: wrap;
		font-size: 11px;
		font-weight: 700;
		&:nth-child(n + 2)::before {
			content: "\00a0|\00a0";
		}
	}
	&__department-text {
		text-transform: uppercase;
		&::after {
			content: "\00a0-\00a0";
		}
	}
	&__course-number {
		&:nth-last-child(n + 2)::after {
			content: ",\00a0";
		}
	}
	&__instructors {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	&__instructor {
		font-weight: 700;
		color: $red;
		&:nth-last-child(n + 2)::after {
			content: ",\00a0";
		}
	}
	&__description {
		p:first-child {
			margin-top: 0.5em;
		}
	}
}