.l-news-events {
  &-boundary {
    padding: 42px 34px 120px;
    @include bp(md) {
      padding-top: 55px;
      /*New*/
      padding: 42px 34px 140px;
        /*New*/
    }
  }

  &-views-staging {
    @include bp(md) {
      max-width: 771px;
      width: 100%;
      margin: 0 auto;
      @include display(flex);
    }
  }

  &-staging {
    @include bp(md) {
      border: 1px solid $charcoal;
      padding-bottom: 63px;
      padding-top: 63px;
      max-width: 1170px;
      margin: 0 auto;
      /*New*/
      position: relative;
      /*New*/
    }
  }

  .view {
    @include bp(md) {
       @include flex(1);
      padding-right: 30px;
    }
  }

  .homepage-event-title,
  .homepage-news-title {
    font-weight: 700;
    font-family: $roboto-slab;
    color: $teal;
    font-size: 1.75rem;
    line-height: 37px;
    @include flex(2);
    text-align: center;
    @include bp(md) {
      font-size: 1.5rem;
      @include flex(1);
      margin-top: -10px;
    }
  }

  hr {
    margin-top: 0;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $charcoal;
    @include flex(1);
  }

  .view-header {
    @include display(flex);
    @include align-items(center);
    padding-bottom: 34px;
  }

  .article-homepage-title,
  .event-homepage-title {
    color: $charcoal;
    font-size: 1.125rem;
    text-align: center;
    @include bp(md) {
      font-size: 0.938rem;
    }

    p {
      margin: 0;
    }
  }

  .field-name-field-event-date {
    font-weight: 700;
    text-align: center;
    font-size: 0.813rem;
    padding-top: 10px;
    /*New*/
    position: relative;
    width: 100%;
      @include bp(md) {
          position: absolute;
          max-width: 355px;
      }
    /*New*/
  }

  .view .views-row {
    padding-bottom: 34px;
    margin-bottom: 34px;
    border-bottom: 1px solid $light-gray;
    @include bp(md) {
      padding-bottom: 69px;
      margin-bottom: 0;
      border-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
      @include bp(md) {
        padding-bottom: 60px;
      }
    }
  }

  .more-link {
    text-align: center;
    padding-top: 34px;

    a {
      display: block;
      padding-top: 25px;
      padding-bottom: 25px;
      border: 1px solid $teal;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.063rem;
      width: 100%;
      max-width: 245px;
      margin: 0 auto;
      @include swipe-fill-right($teal, #FFF);
      @include bp(md) {
        padding-top: 13px;
        padding-bottom: 13px;
        font-size: 0.813rem;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .view-news {
    padding-bottom: 60px;
    @include bp(md) {
      padding-bottom: 0;
    }
  }
}



/*New*/
// .l-news-events-views-staging{
//   .view-news,
//   .view-events{
//       //background-color: red;
//   }
//
//   .view-content{
//     //background-color: blue;
//     @include bp(md) {
//         height: 65%;
//     }
//   }
// }
/*New*/


/*New*/

.l-news-events-views-staging{
  .view-content{
    //background-color: blue;
    @include bp(md) {
      margin-bottom: 25px;
    }
  }
}

.l-news-events .more-link {
  position: relative;
  @include bp(md) {
    position: absolute;
    bottom: 35px;
    max-width: 355px;
    width: 100%;
  }
}
/*New*/
