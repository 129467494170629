.l-pull-quote-boundary {
  background-color: $blue;
  border-top: 14px solid $dark-blue;
  margin-top: 86px;
  padding-bottom: 60px;

  &.teal {
    background-color: $teal;
  }

  &.red {//#004683
    background-color: $blue;
  }
  @include bp(md) {
    padding-bottom: 60px;
  }

  a {
    color: #FFF;
    text-decoration: underline;
  }

  .quote-link {
    color: #FFF;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.063rem;
    text-align: center;
    @include bp(md) {
      font-size: 0.813rem;
    }

    &:hover {
      text-decoration: none;
      @include bp(md) {
        .arrow-extendable {
          @include animation(arrow-rise .3s);
          @include animation-fill-mode(forwards);
          @include animation-timing-function(ease-out);
        }
      }
    }
  }
}

.l-pull-quote-stage {
  @include body-layout();
  color: #FFF;

  .field-name-field-quote-content {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
    text-align: center;
    max-width: 811px;
    margin: 0 auto;

    p {
      margin-bottom: 10px;
    }
  }

  .field-name-field-quote-attribution {
    font-weight: 700;
    text-align: center;
    font-size: 0.938rem;
    text-transform: uppercase;
    line-height: 30px;
    @include bp(md) {
      font-size: 0.813rem;
    }
  }
}

.l-quote-penn-shield {
  margin-top: -58px;
  margin-bottom: 38px;

  img {
    width: 166px;
    height: 166px;
    margin: 0 auto;
    box-shadow: 8px 2px 10px 0 rgba(0,0,0,0.1);
    border-radius: 168px;
  }
}

.l-circle-image {
  position: relative;

  .field-name-field-quote-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 12px;

    img {
      border-radius: 50%;
    }
  }
}

.l-link-content {
  max-width: 811px;
  margin: 0 auto;

  .quote-title {
    display: inline-block;
    padding-bottom: 10px;
  }
}
