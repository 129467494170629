.l-staff-landing-page {
  padding-bottom: 70px;
}

.view-netter-center-staff {
  .staff-landing-statement {
    padding-bottom: 40px;
    @include bp(sm) {
      padding-bottom: 47px;
    }
  }

  p {
    margin: 0;
  }

  .h3,
  h3 {
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 28px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: 300;
    color: #3C3B3B;
    padding-left: 10px;
    //display: none;
  }

  .bio-photograph {
    display: block;

    img {
      @include bp(sm) {
        width: 100%;
      }
    }

    &-hover {
      margin-bottom: 13px;
      border: #ffffff 2px solid;
      padding: 4px;
      @include transition(border .3s ease);

      &:hover {
        border: #00A4A6 2px solid;
      }
    }
  }

  .bio-display-name {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 24px;
    @include bp(sm) {
      font-size: 0.813rem;
      line-height: 15px;
    }

    a {
      color: $charcoal;
      @include transition(color .3s ease);

      &:hover {
        color: $teal;
        text-decoration: none;
      }
    }
  }

  .field-name-field-professional-title {
    font-style: italic;
    font-size: 1rem;
    @include bp(sm) {
      font-size: 0.750rem;
      line-height: 15px;
    }
  }

  .views-row {
    padding-bottom: 50px;
  }

  .bio-related-content,
  .field-name-field-email,
  .field-name-field-phone-number {
    font-size: 0.875rem;
    @include bp(sm) {
      font-size: 0.688rem;
    }
  }

  .field-name-field-email {
    a {
      color: $charcoal;
    }
  }

  .bio-related-content {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 16px;
    color: $charcoal;
  }

  .l-staff-teaser-block {
    h3 {
      padding-bottom: 0;
    }
  }

  .views-exposed-widgets {
    padding-bottom: 20px;
    margin-bottom: 32px;
  }

  .views-exposed-widget label {
    font-weight: 400;
    font-size: 1.125rem;
    background-color: white;
    padding-bottom: 10px;
  }

  .views-exposed-widget {
    float: none;
    padding: 0;
  }

  .form-type-select {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 33px;
  }

  .views-submit-button {
    padding-left: 22px;
  }

  .views-exposed-form {
    background-color: $lightest-gray;

    select {
      border: 0;
      border-radius: 0;
      height: 42px;
      width: calc(100% + 42px);
      padding-right: 42px;
      padding-left: 7px;
      background: url("#{$path-images}red-arrow-box.svg") no-repeat;
      background-color: white;
      background-size: contain;
      background-position: right;
      max-width: 288px;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }
}

.l-leadrship-team,
.l-staff-teaser-block {
  .view-content {
    @include bp(sm) {
       @include display(flex);
      @include flex-wrap(wrap);
      @include justify-content(space-around);
    }
  }

  .views-row {
    @include bp(sm) {
       @include flex(0 0 50%);
      padding-right: 15px;
    }

    // @include bp(md) {
    //    @include flex(0 0 50%);
    // }

    @include bp(lg) {
       @include flex(0 0 32%);
    }

  }
}

.l-bio-teaser {
  &:hover {
    .bio-photograph-hover {
      border: $teal 2px solid !important;
    }

    .bio-display-name {
      a {
        color: $teal !important;
      }
    }
  }
}
