.l-participants {
  //background: repeating-linear-gradient( 145deg, #0d4f8b, #0d4f8b 4px, #004682 4px, #004682 23px );
  background: url("../images/lines_flip_blue.png") #0d4f8b repeat;
  background-size: cover;

  .field-name-field-audience-subheadin {
    color: #FFF;
    font-family: $roboto-slab;
    font-size: 1.75rem;
    line-height: 36px;
    text-align: center;
    padding-bottom: 37px;
    font-weight: 700;
  }

  &-boundary {
    padding: 37px 34px 58px;
  }

  .field-name-field-audience-callout-square {
    @include bp(md) {
      max-width: 1157px;
      margin: 0 auto;
    }

    a {
      color: #FFF;
      font-weight: 700;
      font-size: 1.063rem;
      line-height: 21px;
      display: inline-block;
      padding-top: 50px;
      border: 1px solid #FFF;
      width: 100%;
      min-height: 147px;
      padding-left: 10px;
      padding-right: 10px;
      max-width: 147px;
      /* Careful, this breaks the word wherever it is without a hyphen */
      overflow-wrap: none;
      word-wrap: none;
      -ms-word-wrap: none;
      /* Adds a hyphen where the word breaks */
      -webkit-hyphens: none;
      -ms-hyphens: none;
      -moz-hyphens: none;
      hyphens: none;
      @include transition(background-color .3s ease, color .3s ease);
      @include bp(md) {
        min-height: 178px;
        max-width: 178px;
        padding-top: 70px;
        font-size: 1.125rem;
      }

      &:hover {
        text-decoration: none;
        background-color: #FFF;
        color: $blue;
      }
    }

    .field-items {
      @include display(flex);
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      @include flex-direction(row);
    }

    .field-item {
      //  @include flex(0 0 50%);
      @include display(flex);
      @include justify-content(center);
      @include align-items(center);
      text-align: center;
      padding-bottom: 35px;
      padding-right: 0;
      padding-left: 0;
      @include flex(0 0 50%);
      @include bp(md) {
         @include flex(0 0 20%);
      }
    }
  }
}

//Test
