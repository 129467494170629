.l-bio-detail {
  img {
    margin: 0;

    @include bp(md) {
      max-width: 157px;
    }
  }

  .l-leadership-title{
    font-family: $roboto-slab;
    font-size: 1rem;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .field-name-field-email {
    a {
      color: $charcoal;
    }
  }

  .field-name-field-related-programs {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 16px;
    color: $charcoal;

    font-size: 0.875rem;

    @include bp(md) {
      font-size: .625rem;
    }
  }
}
