.resource-list.publications {
  &-list {
    background-color: #ffffff !important;
    border-bottom: 1px solid #E0E1E3;
    padding-left: 0;
    margin-bottom: 20px;
    padding-top: 22px;
  }
}

.resource-list.publications-list .content {
  line-height: 9px !important;
  color: #3C3B3B !important;
  font-size: 12px;
  margin-top: 9px;
  
}

.resource-list.publications-list a {
  font-size: 15px;
  color: #3C3B3B;
  font-weight: 700;
  margin-bottom: 9px;
}

.pub-title {
  font-size: 18px;
  font-weight: 300px;
}
