.call-to-action {
  margin-bottom: 40px;

  @include bp(md) {
    margin-bottom: 50px;
  }
}

.l-inline-cta {
  background-color: $lightest-gray;
  margin-bottom: 15px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 23px;
  padding-bottom: 31px;

  &:last-child {
    margin-bottom: 0;
  }

  .inline-cta-heading {
    font-weight: 700;
    margin-bottom: 0;
    position: relative;

    p, br {
      display: none;
    }

    a {

      &:hover {
        text-decoration: none;
        @include bp(md) {
          .arrow-extendable {
            @include animation(arrow-rise .3s);
            @include animation-fill-mode(forwards);
            @include animation-timing-function(ease-out);
          }
        }
      }
    }
  }
}
