.alert-bar {
  background-color: $red;

  .content {
    color: #FFF;
    font-family: $open-sans;
    font-size: .813rem;
    text-align: center;
    @include max-layout();
    padding-top: 10px;
    padding-bottom: 6px;
  }

  p {
    margin: 0;
  }

  a {
    color: #FFF;
    text-decoration: none;
  }
}
