$ease: ease;
$duration: 400ms;

.menu-collapsed__expand {
	@include reset-button;
	display: flex;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 35px;

  .icon {
    position: absolute;
  }

  @include bp(md) {
    display: none;
  }
}

.plus-to-minus {
	width: 25px;
	height: 25px;
	cursor: pointer;
	transition: transform $ease $duration;

	&:before, &:after {
		content: '';
		position: absolute;
		background-color: #FFF;
	}
	&:before {
		left: 50%;
		margin-left: -2px;
		width: 2px;
		height: 25px;
	}
	&:after {
		top: 50%;
		left: 0;
		margin-top: -2px;
		height: 2px;
		width: 25px;
		transition: transform $ease $duration;
	}
	&.minus {
		transform: rotate(-90deg);
		&:after {
			transform: rotate( -90deg);
		}
	}
}
