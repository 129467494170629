.sharethis {
  &-label {
    font-weight: 700;
    vertical-align: top;
    padding-right: 7px;

    &:hover {
      cursor: pointer;
    }
  }

  &-click {
    position: relative;
    z-index: 1;
  }

  &-button {
    width: 23px;
    height: 23px;
    padding: 0;
    margin: 0;
    border: 0;
    -webkit-appearance: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background: url("#{$path-images}box-plus-black.svg") no-repeat;
    background-size: contain;

    &.active {
      background: url("#{$path-images}box-minus-black.svg") no-repeat;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-wrapper {
    @include display(flex);
    @include flex-direction(column);

    &.active {
      .st_facebook {
        @include transform(translateZ(-4em) translateY(-8px));
        @include bp(xs) {
           @include transform(translateY(-6px));
        }
      }

      .st_twitter {
        @include transform(translateZ(-8em) translateY(18px));
        @include bp(xs) {
           @include transform(translateY(20px));
        }
      }

      .st_email {
        @include transform(translateZ(-12em) translateY(44px));
        @include bp(xs) {
           @include transform(translateY(46px));
        }
      }
    }

    > span {
      position: absolute;
      right: 4px;
      top: 28px;
      @include bp(xxs) {
        top: 29px;
      }
      @include transition(transform .5s ease);
    }

    .st_facebook {
      @include transform(translateZ(-1em) translateY(-35px));
      @include bp(xs) {
         @include transform(translateY(-31px));
      }
    }

    .st_twitter {
      @include transform(translateZ(-2em) translateY(-35px));
      @include bp(xs) {
         @include transform(translateY(-31px));
      }
    }

    .st_email {
      @include transform(translateZ(-3em) translateY(-35px));
      @include bp(xs) {
         @include transform(translateY(-31px));
      }
    }
    // use of important to override module css
    .chicklets {
      width: 23px!important;
      height: 23px!important;

      &.facebook {
        background: url("#{$path-images}chicklet-fb.svg") no-repeat;
        background-size: contain;
      }

      &.twitter {
        background: url("#{$path-images}chicklet-twitter.svg") no-repeat;
        background-size: contain;
      }

      &.email {
        background: url("#{$path-images}chicklet-email.svg") no-repeat;
        background-size: contain;
      }

      &.email,
      &.facebook,
      &.twitter {
        @include bp(xs) {
          margin-top: -14px;
          position: absolute;
          margin-left: -16px;
        }
      }

      &:hover {
        opacity: 1!important;
      }
    }
  }
}

.l-sharethis {
  position: relative;
  text-align: right;

  &:hover {
    .sharethis-label {
      color: $red;
    }

    .sharethis-button {
      background: url("#{$path-images}box-plus-red.svg") no-repeat;

      &.active {
        background: url("#{$path-images}box-minus-red.svg") no-repeat;
      }
    }
  }
}
