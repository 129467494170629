.l-mobile-menu {
  background-color: $teal;


  ul.menu li {
    margin: 0;
    padding: 0;
  }


  &.menu-collapsed {
    &__menu {

      ul {
        position: relative;
        margin: 0;
        padding: 0;
      }

      li {
        display: block;
        position: relative;
        padding-left: 0;
      }

      .link-container {
        position: relative;

        a {
          display: block;

        }

        .menu-collapsed__expand {
          padding-top: 30px;
        }
      }

      // Accordion menus
      .sub-menu {
        display: none;
      }

      .menu {
        &__list-item {

          &:after {
            content: '';
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
          }
        }

        &__link {
          margin: 0;
          padding-top: 30px;
          padding-bottom: 34px;
          padding-left: 35px;
          padding-right: 65px;
          text-align: left;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }

          &.active {
          }
        }

        &__level1 {

          &__list-item {
            border-bottom: 1px solid #FFF;

            &:last-child {
              border-bottom: 0;
            }

            &:after {
              right: 45px;
            }

            &.first {
              > .link-container {
                .menu-collapsed__expand {

                }

                a {
                  padding-top: 40px;
                  padding-bottom: 34px;
                }
              }
            }
          }

          &__link {
            font-family: $roboto-slab;
            text-transform: uppercase;
            font-size: 1.25rem;
            color: #FFF;
          }
        }

        &__level2 {

          &__list-item {
            background-color: #FFF;
            border-bottom: 1px solid $teal;

            .plus-to-minus:before, .plus-to-minus:after {
              background-color: $charcoal;
            }

            &:last-child {
              border-bottom: 0;
            }

            &:after {
              left: 15px;
            }
          }

          &__link {
            font-family: $open-sans;
            color: $charcoal;
            text-transform: uppercase;
            font-size: 1.25rem;
          }
        }

        &__level3 {

          &__list-item {
            background-color: $lightest-gray;
            border-bottom: 1px solid #D8D8D8;

            &:last-child {
              border-bottom: 0;
            }

            &:after {
              left: 30px;
            }
          }

          &__link {
            font-family: $open-sans;
            font-weight: 700;
            color: $charcoal;
            font-size: 1.25rem;
          }
        }

        &__level4 {

          &__list-item {
            background-color: $light-gray;
            border-bottom: 1px solid #CFCFCF;

            &:last-child {
              border-bottom: 0;
            }

            &:after {
              left: 45px;
            }
          }

          &__link {
            color: $charcoal;
            font-family: $open-sans;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

.c-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0;
  background-color: #000;
  opacity: 0;
  @include transition(opacity .3s, width 0s .3s, height 0s .3s);
  display: none;

  @include bp(xs) {
    display: block;
  }

  @include bp(md) {
    display: none;
  }

  &.is-active {
    width: 100%;
    height: 100%;
    opacity: .7;
    @include transition(opacity .3s);
  }
}

.l-header_bottom {
  display: none;
  z-index: 200;
  top: 94px;
  height: auto;
  overflow-y: scroll;
  background-color: $teal;
  left: 0;
  @include transition(transform .3s);
  @include transform (translateX(-100%));
  position: absolute;
  max-width: 450px;
  width: 100%;

  &.is-active {
    @include transform (translateX(0));
    display: block;

    @include bp(md) {
      top: 0;
      display: none;
    }
  }
}

.l-mobile-primary-nav {
  border-top: 1px solid white;

  a {
    font-family: $roboto-slab;
    text-transform: uppercase;
    font-size: 1.25rem;
    color: #FFF;
    text-decoration: none;
    display: block;

    padding-top: 30px;
    padding-bottom: 34px;
    padding-left: 35px;
    padding-right: 65px;
  }

  li {
    border-bottom: 1px solid #FFF;

    &:last-child {
      border-bottom: 0;
    }

    &.search-menu-item {
      display: none;
    }
  }
}
