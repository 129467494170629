.l-news-landing {

  padding-bottom: 44px;

  @include bp(md) {
    padding-bottom: 0;
  }

  // reset annoying drupal css
  .views-exposed-form .views-exposed-widget {
    float: none;
    display: inline-block;
    padding: 0;
  }

  .views-exposed-widgets {

    @include bp(md) {
      @include display(flex);
      @include flex-direction(row);
      @include justify-content(space-between);
      @include flex-wrap(wrap);
    }
  }

  .container-inline-date .form-item,
  .container-inline-date .form-item input {
    width: 100%;
  }

  .container-inline-date .date-padding {
    float: none;
  }

  .view-filters {
    background-color: $lightest-gray;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 40px;

    label {
      font-weight: 400;
    }

    select {
      border: 0;
      border-radius: 0;
      height: 30px;
      width: calc(100%);
      padding-right: 30px;
      padding-left: 7px;
      background: url(#{$path-images}red-arrow-box.svg) no-repeat;
      background-color: white;
      background-size: contain;
      background-position: right;
      max-width: 770px;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-size: 0.6875rem;
      box-sizing: border-box;
    }

    input[type="submit"] {
      //padding-left: 64px;
      //padding-right: 64px;
      font-size: 0.9375rem;
      height: 30px;
      padding-top: 6px;
    }
  }

  #edit-date-filter-wrapper,
  #edit-field-related-programs-tid-wrapper {
    width: 100%;

    @include bp(md) {
      width: auto;
      @include flex(25%);
      padding-right: 20px;
      @include display(flex);
      @include flex-direction(column);
      @include justify-content(space-between);
    }
  }

  #edit-field-article-title-value-wrapper {
    float: none;
    width: 100%;
    padding-bottom: 20px;

    @include bp(md) {
      @include flex(0 0 100%);
    }

    input {
      width: 100%;
      height: 30px;
      color: $charcoal;
      font-size: 0.875rem;
      padding-left: 9px;
      border: 1px solid $light-gray;
    }
  }

  .views-row {
    padding-bottom: 29px;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 25px;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  .pager {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    > li {
      padding: 0;
      a {
        padding: 0.5em;
      }
    }
    .pager-first {
      a {
        text-transform: capitalize;
        background: url("#{$path-images}chev-arrow-teal-left.svg") no-repeat;
        padding-left: 16px;
        background-size: 12px 12px;
        background-position: 0% 50%;
      }
    }
    .pager-last {
      a {
        text-transform: capitalize;
        background: url("#{$path-images}chev-arrow-teal-right.svg") no-repeat;
        padding-right: 16px;
        background-size: 12px 12px;
        background-position: 100% 50%;
      }
    }
    .pager-previous {
      a {
        margin-bottom: 2px;
        font-size: 0;
        background: url("#{$path-images}chev-arrow-teal-left.svg") no-repeat !important;
        background-size: 12px 12px;
      }
    }
    .pager-next {
      a {
        margin-bottom: 2px;
        font-size: 0;
        background: url("#{$path-images}chev-arrow-teal-right.svg") no-repeat !important;
        background-size: 12px 12px;
      }
    }
    .pager-ellipsis,
    .pager-item,
    .pager-current {
      display: none;
      @include bp(md) {
        display: inline;
      }
    }
  }
}

.teaser-article {
  &-link-title {
    color: $charcoal;
    font-size: 1.438rem;
    line-height: 32px;

    @include bp(md) {
      font-size: 1.125rem;
    }

    p {
      margin: 0;
    }
  }

  &-meta {
    font-weight: 700;

    > div {
      display: inline-block;
    }
  }

  &-external {
    font-weight: 300;
    font-style: italic;
    color: $charcoal;
    display: block;
    margin-top: 5px;

    &.news-full {
        width: auto;
        height: auto;
        border: 1px solid #3C3B3B;
        padding: 8px 30px;
        position: relative;
        display: block;
        margin-top: 28px;

        @include bp(md) {
          position: absolute;
        }
    }


    @include bp(md) {
      font-size: .750rem;
    }

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      background: url(#{$path-images}diagonal-arrow.svg) no-repeat;
      background-size: contain;
      display: inline-block;
      margin-left: 10px;
    }
  }
}
//
// a.teaser-article-external.news-full {
//     width: auto;
//     height: auto;
//     border: 1px solid #3C3B3B;
//     padding: 8px;
//     position: absolute;
//     display: block;
//     margin-top: 28px;
// }
