/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */
/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/
/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: block;
  vertical-align: top;
  max-width: 400px;
  height: 30px;
  padding: 5px 10px;
  /* width: 100%; */
  margin-right: 20px;
  background-color: #ffffff;
  //overflow: hidden;
  width: 100%;
  @include bp(xxs) {
    margin-right: 0;
  }
}
//************Contianers********************//
//Programs

span#edit-date-filter-value-yearSelectBoxItContainer,
span#edit-field-related-programs-tidSelectBoxItContainer {
  width: 215px ;
  background: #ffffff;
  position: relative;
  right: 0;
  margin-left: 20px;
  @include bp(sm) {
    //margin-left: 0px;
  }
  @include bp(md) {
    width: 271px ;
    margin-left: 0;
  }
}
//************Contianers********************//
//************SelectBoxItText********************//
//Programs

span#edit-field-related-programs-tidSelectBoxItText {
  // width: 271px ;
  // background: #ffffff;
  // bottom: 0;
  // position: absolute;
  // right: 15px;
  width: 210px ;
  background: #ffffff;
  bottom: 0;
  position: absolute;
  right: 32px;
  // @include bp(xxs) {
  //    width: 271px ;
  //
  //   }
  @include bp(lg) {
    width: 240px ;
    right: 32px;
  }
  @include bp(lg2) {
    width: 271px ;
  }
}
//Years

span#edit-date-filter-value-yearSelectBoxItText {
  width: 210px ;
  /* width: 100%; */
  background: #ffffff;
  bottom: 0;
  position: absolute;
  right: 32px;
  @include bp(xxs) {
    width: 210px ;
  }
  @include bp(lg) {
    width: 261px ;
  }
  @include bp(lg2) {
    width: 271px ;
  }
}
//Academic Year

span#edit-field-academic-year-valueSelectBoxItText {
  width: 300px ;
  /* width: 100%; */
  background: #ffffff;
  bottom: 0;
  position: absolute;
  right: 32px;
  border: 1px solid #E0E1E3;
  @include bp(xs) {
    width: 500px ;
  }

  @include bp(md) {
    width: 371px ;
  }
}
//Deprtment

span#edit-field-department-value-selectiveSelectBoxItText {
  width: 225px ;
  /* width: 100%; */
  background: #ffffff;
  bottom: 0;
  position: absolute;
  right: 32px;

  @include bp(xs) {
      width: 325px ;
  }

  @include bp(sm) {
       width: 350px ;
  }
}
//************SelectBoxItText********************//
//************SelectBoxItOptions********************//
ul#edit-field-related-programs-tidSelectBoxItOptions {
  top: 5px ;
  left: -20px;
  width: 277px ;
}

ul#edit-field-related-yeartidSelectBoxItOptions {
  top: 5px ;
  left: -20px;
  width: 277px ;
}

ul#edit-date-filter-value-yearSelectBoxItOptions {
  top: 5px ;
  left: -20px;
  width: 277px ;
}
// ul#edit-field-academic-year-valueSelectBoxItOptions{
//   top: 5px ;
//   left: 0px;
//   width: 290px ;
// }
//************SelectBoxItOptions********************//
// select.form-item-field-related-programs-tid {
//     width: 100%;
//     height: 30px;
//     background-color: #ffffff;
//     padding: 5px 10px 5px 10px;
// }
/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  font: 14px Helvetica, Arial;
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap;
}
/* Button */
.selectboxit-container .selectboxit {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 0;
  //overflow: hidden;
  display: block;
  position: absolute;
  right: -3px;
  background: url("../../images/svg/red-arrow-box.svg") no-repeat;
  top: 0;
}
/* Height and Vertical Alignment of Text */
.selectboxit-container .selectboxit-options a,
.selectboxit-container span {
  //height: 30px;
  /* Height of the drop down */
  //width: 26px;
  //background-color: $red;
  line-height: 30px;
  /* Vertically positions the drop down text */
  display: block; //  margin-top: -10px;
}
/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  .selectboxit-text {
    outline: 1px solid $red;
  }
}
/* Disabled Mouse Interaction */
.selectboxit-options .selectboxit-disabled,
.selectboxit.selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}
/* Button Text */
.selectboxit-text {
  text-indent: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  color: #000;
  max-width: 500px;
  width: 100%;
  //background-color: red;
  position: absolute;
}
//
// .selectboxit-container span {
//     height: 30px;
//     line-height: 30px;
//     width: 375px;
//     display: block;
//     right: 0px;
//     color: #000;
// }

.selectboxit .selectboxit-option-icon-container {
  margin-left: 0;
}
/* Options List */
.selectboxit-container .selectboxit-options {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 94%;
  /* Minimum Width of the dropdown list box options */
  *width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  //cursor: pointer;
  display: none;
  z-index: 9999999999999;
  border-radius: 0;
  text-align: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-left: -15px;
  margin-top: -5px;
}
/* Individual options */
.selectboxit-option .selectboxit-option-anchor {
  padding: 0 2px;
}
/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none;
  background-color: $red;
  color: #ffffff;
}
/* Individual Option Optgroup Header */
.selectboxit-optgroup-header,
.selectboxit-option {
  text-indent: 5px;
  /* Horizontal Positioning of the select box option text */
  margin: 0;
  list-style-type: none;
}
/* The first Drop Down option */
.selectboxit-option-first {
  //border-top-right-radius: 6px;
  //border-top-left-radius: 6px;
}
/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
/* The last Drop Down option */
.selectboxit-option-last {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold;
}
/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
  //  cursor: default;
}
/* Drop Down down arrow container */
.selectboxit-arrow-container {
  /* Positions the down arrow */
  width: 30px;
  height: 26px;
  position: absolute;
  right: 0;
}
/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  /* Horizontally centers the down arrow */
  //margin: 0 auto;
  position: absolute;
  //top: 50%;
  right: 0;
  left: 0;
  display: none;
}
/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
  top: 30%;
}
/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
  float: left;
}

.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left;
}

.selectboxit-rendering {
  display: inline-block ;
  *display: inline ;
  zoom: 1 ;
  visibility: visible ;
  position: absolute ; //  top: -9999px ;
  //  left: -9999px ;
}
/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit;
}
/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px;
}
/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-btn {
  background-color: $red;
}


.selectboxit-btn.selectboxit-enabled:active,
.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:hover {
  //color: #333333;
}

.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:hover {
  //  color: #333333;
  text-decoration: none; //  background-position: 0 -15px;
}

.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.selectboxit-list {
  background-color: #ffffff;
  border: 1px solid #ccc;
  width: 325px;
}

.selectboxit-list .selectboxit-option-anchor {
  color: #333333;
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: #ffffff;
  background-color: $red;
  width: 100%;
  top: 0;
  left: 0;
  color: #000;
}

.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
}
