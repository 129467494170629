.node-type-page {
  .field-name-body {
    iframe {
      @include padding-reusable-component();
      width: 100%;
    }

    table {
      margin-bottom: 40px;
      @include bp(md) {
        margin-bottom: 50px;
      }
    }
  }
}
//Responsive tables
.field-name-body {
  table {
    text-align: center;
    width: 100%;
    font-size: 0.875rem;
    @include bp(md) {
      font-size: 0.8125rem;
    }

    tr {
      background-color: $lightest-gray;
      border-bottom: 2px solid #FFF;
      @include bp(xs2) {
        &:nth-child(2n) {
          background-color: $lightest-gray;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      &:nth-child(2n) {
        background-color: $light-gray;
      }
    }

    tbody {
      border-top: 0;
    }

    th {
      display: none;
      background-color: $red;
      color: #FFF;
      font-weight: 400;
      border-right: 1px solid #FFF;
      border-bottom: 0;
      border-left: 0;
      border-top: 0;
      padding-top: 8px;
      padding-bottom: 14px;

      &:last-child {
        border-right: 0;
      }
    }

    td {
      display: block;
      padding-left: 32px;
      padding-right: 32px;
      @include bp(xs2) {
        padding-top: 8px;
        padding-bottom: 14px;
        border-right: 1px solid #FFF;

        &:last-child {
          border-right: 0;
        }
      }

      &:before {
        content: attr(data-th); // who knew you could do this? The internet, that's who.
        padding-right: 15px;
        display: inline-block;
        // optional stuff to make it look nicer
        width: 50%; // magic number :( adjust according to your own content
        // end options
        @include bp(xs2) {
          display: none;
        }
      }
    }

    td,
    th {
      text-align: left;
      @include bp(xs2) {
        display: table-cell;
        text-align: center;
      }
    }
  }
}
// Hero image
#hero-image-boundary {
  display: none;
}

body.hero-image-present {
  @include bp(md) {
    #hero-image-boundary {
      display: block;
      position: absolute;
      width: 100%;
      z-index: -99;
      border-bottom: 10px solid #004a7f;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.2s;
    }

    .l-main-content-boundary {
      padding: 0;
    }

    .l-main-content-stage {
      margin-top: 200px;
      background: #fff;
      padding: 15px 85px 30px 28px;
    }
  }
}
