$yellow: #FFD15B;
$teal: #00787A;
$blue: #004683;
$dark-blue: #002F59;
$darkest-blue: #000F3A;
$red: #C2004D;
$charcoal: #3C3B3B;
$light-gray: #E0E1E3;
$light-brown: #F0EFE8;
$lightest-gray: #F2F2F3;

$path-images: '../../images/svg/';
