.l-event-landing {
  padding-bottom: 44px;

  .time-divider {
    display: inline-block;
  }

  .event-date,
  .time-divider,
  .field-name-field-start-time,
  .field-name-field-end-time,
  .field-name-field-event-location,
  .field-name-field-related-programs {
        font-weight: 900;
        font-size: .750rem;

    p {
      margin: 0;
    }
  }

  .field-name-field-event-date,
  .date-divider,
  .time-divider,
  .field-name-field-start-time,
  .field-name-field-end-time {
    display: inline-block;
  }

  .view-filters {
    background-color: $lightest-gray;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 40px;

    label {
      font-weight: 400;
    }

    select {
	    border: 0;
	    border-radius: 0;
	    height: 30px;
	    width: calc(100%);
	    padding-right: 42px;
	    padding-left: 7px;
	    background: url(#{$path-images}red-arrow-box.svg) no-repeat;
	    background-color: white;
	    background-size: contain;
	    background-position: right;
	    max-width: 365px;
	    -webkit-appearance: none;
	    -moz-appearance: none;
	    font-size: 0.6875rem;
      box-sizing: border-box;

      @include bp(sm) {
            width: calc(90%);
      }
	  }

    input[type="submit"] {
    //  padding-left: 64px;
    //  padding-right: 64px;
      font-size: 0.9375rem;
      height: 30px;
      padding-top: 6px;
    }
  }

  #edit-field-event-title-value-wrapper {
    float: none;
    width: 100%;

    input {
      width: 100%;
      height: 30px;
      color: $charcoal;
      font-size: 0.875rem;
      padding-left: 9px;
      border: none;
      display: none;
    }
  }

  #edit-field-related-programs-tid-wrapper {
    padding-top: 10px;
    margin-right: 15px;

    @include bp(md) {
      padding-right: 40px;
    }
  }

  &-header {
    @include display(flex);
    text-align: center;
    padding-bottom: 30px;

    > div  {
      @include flex(1);
    }

    a {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.8125rem;
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      color: $light-gray;
      border-right: 1px solid $light-gray;
      border-left: 1px solid $light-gray;
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      @include transition(color .3s ease, border .3s ease);

      &:hover {
        text-decoration: none;
        color: $blue;
        border-right: 1px solid $blue;
        border-left: 1px solid $blue;
        border-top: 1px solid $blue;
        border-bottom: 1px solid $blue;
      }

      &.active {
        color: #FFFFFF;
        background-color: $blue;
        border-right: 1px solid $blue;
        border-top: 1px solid $blue;
        border-bottom: 1px solid $blue;
        border-left: 1px solid $blue;
      }
    }
  }

  .views-row {
    padding-bottom: 28px;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 25px;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 0;
    }

    @include bp(md) {
      padding-bottom: 25px;
      margin-bottom: 22px;
    }
  }

  .view-empty {
    text-align: center;
  }
}

.teaser-event-title {
  color: $charcoal;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 2rem;

  @include bp(md) {
    font-size: 1.125rem;
  }

  p {
    margin-bottom: 8px;
    margin-top: 0;
  }
}

div#edit-field-event-title-value-wrapper {
  display: none;
}


.form-item.form-type-textfield.form-item-combine {
  input {
    width: 100%;
    height: 30px;
    color: #3C3B3B;
    font-size: 0.875rem;
    padding-left: 9px;
    border: none;
  }
}
