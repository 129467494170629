$ease: ease;
$duration: 400ms;

.header__hamburger {
	@include reset-button;
	display: flex;
  position: relative;
  width: 30px;
	height: 24px;
	cursor: pointer;
  margin-top: 9px;

	&:before, &:after {
		content: '';
	}
	.hamburger-middle, &:before, &:after {
		position: absolute;
		width: 30px;
		height: 3px;
		background-color: #FFF;
		border-radius: 10px;
	}
	.hamburger-middle {
		top: 50%;
		margin-top: -2px;
		transition: transform $ease ($duration/2);
	}
	&:before {
		top: 0;
		transition: top ($duration/2) $ease ($duration/2), transform $ease ($duration/2);
	}
	&:after {
		bottom: 0;
		transition: bottom ($duration/2) $ease ($duration/2), transform $ease ($duration/2);
	}

	&.close {
		.hamburger-middle {
			transform: rotate(-45deg);
			transition: transform ($duration/2) $ease ($duration/2);
		}
		&:before {
			top: 10px;
			transform: rotate(-45deg);
			transition: top $ease ($duration/2), transform ($duration/2) $ease ($duration/2);
		}
		&:after {
			bottom: 11px;
			transform: rotate(45deg);
			transition: bottom $ease ($duration/2), transform ($duration/2) $ease ($duration/2);
		}
	}

  @include bp(md) {
    display: none;
  }
}
