.l-event-info {
  padding-bottom: 40px;
  @include bp(xs3) {
     @include display(flex);
  }

  .event-date,
  .field-name-field-end-time,
  .field-name-field-event-location,
  .field-name-field-related-programs,
  .field-name-field-start-time,
  .time-divider {
    font-weight: 700;
  }

  .date-divider,
  .field-name-field-end-time,
  .field-name-field-event-date,
  .field-name-field-start-time,
  .time-divider {
    display: inline-block;
  }

  .field-name-field-event-location {
    p {
      margin: 0;
    }
  }
}

.event {
  &-title {
    p {
      margin: 0;
    }
  }

  &-date {
    display: inline-block;
  }

  &-meta-information {
    padding-bottom: 30px;
  }

  &-flier-info {
    background-color: $lightest-gray;
    padding: 25px 17px;
    @include bp(xs3) {
      margin-left: 28px;
      @include flex-shrink(0);
      padding-left: 26px;
      padding-right: 26px;
      padding-bottom: 24px;
      @include align-self(flex-start)
    };
  }
}

.l-registration-link {
  margin-bottom: 40px;

  a {
    color: $charcoal;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    /*width: 100%;*/
    padding: 6.5px;
    /*padding-top: 6.5px;
    padding-bottom: 6.5px;*/
    border: 1px solid $charcoal;
    /*max-width: 230px;*/
    @include swipe-fill-right($charcoal, #FFFFFF);
  }
}

.l-event-flier {
  @include display(flex);
  @include flex-direction(row);
  @include bp(xs3) {
     @include flex-direction(column);
  }

  .field-name-field-event-flier-thumbnail {
    padding-right: 11px;
    @include bp(xs3) {
      padding-bottom: 12px;
      padding-right: 0;
    }
  }

  .flier-download {
    font-weight: 700;
    text-transform: uppercase;
    @include align-self(center);

    > a {
      display: block;
      padding-bottom: 10px;

      &:hover {
        text-decoration: none;
        @include bp(md) {
          .arrow-extendable {
            @include animation(arrow-rise .3s);
            @include animation-fill-mode(forwards);
            @include animation-timing-function(ease-out);
          }
        }
      }
    }
  }

  .l-arrow-extendable {
    @include bp(md) {
      padding-left: 0;
      margin-top: 20px;
    }

    .arrow-extendable {
      margin: 0;
    }
  }
}
