.view-courses {
	dd {
		margin: 0;
	}

	.view-grouping {
		margin-bottom: 60px;
	}

	.view-grouping-header {
		margin-bottom: 15px;
		font-weight: 700;
		font-family: $roboto-slab;
		font-size: 20px;
		line-height: 28px;
	}

	.view-grouping-content {
		h3 {
			display: block;
			margin: 0;
			padding: 15px;
			color: #fff;
			background: $blue url("#{$path-images}plus-white.svg") right 15px center no-repeat;
			// background-size: 100%;
			cursor: pointer;
		}

		.active h3 {
			background-image: url("#{$path-images}minus-white.svg");
		}
	}

	.views-exposed-form {
		margin-top: 45px;

		label {
			font-weight: 400;
		}

		select {
			border: 0;
			border-radius: 0;
			height: 30px;
			width: calc(82% + 42px);
			padding-right: 42px;
			padding-left: 7px;
			background: url("#{$path-images}red-arrow-box.svg") no-repeat;
			background-color: white;
			background-size: contain;
			background-position: right;
			max-width: 288px;
			-webkit-appearance: none;
			-moz-appearance: none;
			font-size: 15px;
			@include bp(md) {
				width: calc(100% + 42px);
			}
		}
	}

	.views-exposed-widget {
		float: none;
	}

	.views-widget-filter-field_academic_year_value {
		margin-bottom: 15px;

		label {
			margin-bottom: 10px;
			font-family: $roboto-slab;
			font-size: 16px;
		}

		select {
			border: 1px solid $lightest-gray;
		}
	}

	.views-widget-filter-field_department_value_selective {
		padding: 30px;
		background-color: $lightest-gray;
		margin-bottom: 42px;

		label {
			margin-bottom: 5px;
		}
	}
}
