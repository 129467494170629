.node-course.node-teaser {
	padding: 30px 60px 15px 30px;
	color: $charcoal;
	background: $lightest-gray;

	h2 {
		margin-bottom: 0;
		font-family: $open-sans;
		font-size: 22px;
	}

	.field-name-field-course-number,
	.field-name-field-instructors {
		display: inline-block;
		font-weight: 700;
		color: $red;
	}

	.field-name-field-course-number {
		vertical-align: top;

		.field-item:after {
			margin: 0 5px;
			content: "|";
		}
	}

	.field-name-field-department {
		font-size: 11px;
		font-weight: 700;
		text-transform: uppercase;
	}
}

li.leaf.menu-mlid-916{
	display: none;
}
