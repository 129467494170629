.l-sidebar-boundary {
  padding-left: 34px;
  padding-right: 34px;
  @include bp(md) {
    padding-left: 0;
    padding-right: 0;
    background-color: #FFF;
    padding-left: 28px;
  }
}

.l-sidebar-nav {
  padding-bottom: 47px;
  border-top: 1.5px dotted $charcoal;

  & > h2 {
    font-size: 1.125rem;
    line-height: 22px;
    margin: em(16px) 0 em(26px);
  }

  &.banner-present {
    padding-top: 21px;
  }
  @include bp(md) {
    border-top: 0;
    min-width: 170px;
    max-width: 170px;
  }

  > h2 {
    font-size: 1.125rem;
    line-height: 22px;
  }

  .plus-to-minus {
    width: 10px;
    height: 10px;

    &.minus {
      &:after,
      &:before {
        background-color: $teal!important;
      }
    }

    &:before {
      height: 10px;
      left: 6px;
    }

    &:after {
      width: 10px;
      top: 6px;
    }
  }

  .plus-to-minus:after,
  .plus-to-minus:before {
    background-color: $charcoal;
  }

  ul.menu li {
    margin: 0;
    padding: 0;
  }

  &.menu-collapsed {
    &__menu {
      ul {
        position: relative;
        margin: 0;
        padding: 0;
      }

      li {
        display: block;
        position: relative;
        padding-left: 0;
      }

      .link-container {
        position: relative;

        a {
          display: block;
        }

        .menu-collapsed__expand {
          @include reset-button;
          display: flex;
          padding-top: 22px;
          right: 0;
          width: 10px;
          height: 10px;
          @include bp(md) {
            display: block;
          }
        }
      }
      // Accordion menus
      .sub-menu {
        display: none;
      }

      .menu {
        &__list-item {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
          }
        }

        &__link {
          margin: 0;
          font-size: 1rem;
          padding-top: 13px;
          padding-bottom: 16px;
          padding-right: 30px;
          text-align: left;
          text-decoration: none;
          @include transition(color .3s ease);
          @include bp(md) {
            font-size: 0.9375rem;
          }

          &:hover {
            text-decoration: none;
            color: $teal;
          }

          &.active {}
        }

        &__level2 {
          &__list-item {
            background-color: #FFF;
            border-bottom: 1px solid #D8D8D8;
            @include bp(md) {
              border-bottom: 1.5px dotted $charcoal;
            }

            .plus-to-minus:after,
            .plus-to-minus:before {
              background-color: $charcoal;
            }

            &:last-child {
              border-bottom: 0;
            }

            &:after {
              left: 15px;
            }

            &.active-parent {
              > .link-container {
                //border-bottom: 1px solid #D8D8D8;
                @include bp(md) {
                  //border-bottom: 1px dotted $charcoal;
                }
              }
            }
          }

          &__link {
            font-family: $open-sans;
            color: $charcoal;
            text-transform: uppercase;
            /*width: 85%;*/

            overflow-wrap: none;
            word-wrap: none;
            -ms-word-wrap: none;
            /* Adds a hyphen where the word breaks */
            -webkit-hyphens: none;
            -ms-hyphens: none;
            -moz-hyphens: none;
            hyphens: none;

            &.active-trail {
              color: $teal;
              width: 85%;
            }
          }
        }

        &__level3 {
          &__list-item {
            padding-left: 0;
            border-bottom: 1.5px dotted #3C3B3B;
          }

          &__link {
            font-weight: 700;
            border-bottom: 1px solid #D8D8D8;
            color: $charcoal;
            width: 100%;

            overflow-wrap: none;
            word-wrap: none;
            -ms-word-wrap: none;
            /* Adds a hyphen where the word breaks */
            -webkit-hyphens: none;
            -ms-hyphens: none;
            -moz-hyphens: none;
            hyphens: none;

            @include bp(md) {
              font-size: 0.8125rem;
              padding-bottom: 10px;
            }

            &.active {
              color: $teal;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }

        &__level4 {
          &__list-item {}

          &__link {
            color: $charcoal;
            border-bottom: 1px solid #D8D8D8;
            padding-left: 15px;

            overflow-wrap: none;
            word-wrap: none;
            -ms-word-wrap: none;
            /* Adds a hyphen where the word breaks */
            -webkit-hyphens: none;
            -ms-hyphens: none;
            -moz-hyphens: none;
            hyphens: none;
            @include bp(md) {
              font-size: 0.8125rem;
              //  padding-left: 15px;
            }

            &.active {
              color: $teal;
            }
          }
        }
        &__level5 {
          &__list-item {}

          &__link {
            //padding-left: 15px;
            padding-left: 30px;

            overflow-wrap: none;
            word-wrap: none;
            -ms-word-wrap: none;
            /* Adds a hyphen where the word breaks */
            -webkit-hyphens: none;
            -ms-hyphens: none;
            -moz-hyphens: none;
            hyphens: none;
          }
        }
      }
    }
  }
}

li.last.leaf.active-trail.active.menu-mlid-917.menu__list-item.menu__level3__list-item.active-parent {
    border: none;
}
