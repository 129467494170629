.field-name-field-related-pdf {
  .field-item {
    font-size: 0.75rem;
    color: $red;
    padding-bottom: 16px;
  }

  &-title {
    font-weight: 700;
    font-size: 1.125rem;
  }
}

.l-related-pdf {
  @include padding-reusable-component();

  &-container {
    //background-color: red;
    display: flex;
    flex-direction: column;
    @include bp(md) {
      flex-direction: row;
    }
  }

  .field-item {
    background-color: $lightest-gray;
    margin-bottom: 20px;
    color: $red;
    @include bp(md) {
      margin-right: 3px;
      margin-bottom: 0;
      @include flex(1);
      max-width: 188px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .field-items {
    @include bp(md) {
       @include display(flex);
    }
  }

  a {
    text-decoration: none;
    font-size: 0.9375rem;
  }
}

.l-related-pdf {
  a {
    margin: 20px 0 0;
    font-weight: 700;
    font-size: 1.125rem;
    background-color: $lightest-gray;
    padding: 20px 20px 50px;
    display: block;
    color: $charcoal;
    border: 1px solid $lightest-gray;
    @include transition(border .3s ease, color .3s ease);
    @include bp(md) {
      margin: 0 3px 0 0;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 12px;
      height: 178px;
      width: 188px;
    }

    &:hover {
      text-decoration: none;
      color: $red;
      border: 1px solid $red;
    }
  }
}
