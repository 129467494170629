.resource-list {
  background-color: #FAFAFB;
  padding-top: 25px;
  padding-left: 14px;
  padding-right: 16px;
  padding-bottom: 20px;
  margin-bottom: 40px;

  color: $charcoal;

  @include bp(md) {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 33px;
    margin-bottom: 50px;
  }

  > p {
    display: none;
  }

  .node-resource-link-list {
    padding-bottom: 17px;
    padding-top: 17px;
    border-bottom: 1px solid #D8D8D8;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  .resource-title {
    margin-bottom: 5px;
    display: block;

    a  {
      color: $charcoal;
      font-weight: 700;
      font-weight: 1.250rem;

      @include bp(md) {
        font-weight: .813rem;
      }
    }
  }

  .resource-divider {
    display: inline-block;
  }

  .field-name-field-optional-source {
  //  padding-bottom: 8px;
    font-size: .875rem;
    line-height: 24px;

    @include bp(md) {
      font-size: .750rem;
      line-height: 17px;
    }
  }

  .field-name-field-optional-byline,
  .field-name-field-resource-date {
    display: inline-block;
    font-size: .875rem;
    line-height: 24px;

    @include bp(md) {
      font-size: .750rem;
      line-height: 17px;
    }
  }
}
