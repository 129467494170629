.inline-slideshow {
  overflow: hidden;
  position: relative;
  max-width: 848px;
  width: 100%;
  padding-bottom: 61px;
  margin-bottom: 40px;
  outline: none;

  &.inline-photo {
    padding-bottom: 0;

    button {
      display: none;
    }

    .field-name-field-slide-caption {
      width: 100%;
      max-width: 100%;
    }
  }
  // @include bp(sm) {
  //   height: 585px;
  // }
  @include bp(md) {
    padding-bottom: 0;
    margin-bottom: 10px;
    //  height: 300px;
  }
  // @include bp(md1) {
  //   height: 345px;
  // }
  // @include bp(md2) {
  //   height: 385px;
  // }
  // @include bp(lg) {
  //   height: 425px;
  // }
  //
  // @include bp(lg1) {
  //   height: 585px;
  // }
  div {
    outline: none;
  }

  .l-slideshow-slide {
    //max-width: 848px;
    width: 100%;
    //height: 585px;
    img {
      width: 100%;
      max-height: 585px;
    }
  }

  .field-name-field-slide-caption {
    font-size: 0.938rem;
    line-height: 24px;
    max-width: 473px;
    @include bp(md) {
      font-size: 0.688rem;
      line-height: 21px;
    }
  }

  button {
    width: 30px;
    height: 41px;
    padding: 0;
    /*background-color: transparent;*/
    background-color: transparent;
    box-sizing: content-box;
    border: 0;
    position: absolute;
    bottom: 0;
    box-shadow: none;
    @include bp(md) {
      /*width: 8px;
      height: 11px;*/
      bottom: 20px;
    }

    svg {
      display: none;
    }

    &.previous {
      background: #fff url("#{$path-images}chev-arrow-charcoal-left.svg") no-repeat center center;
      background-size: contain;
      right: 80px;
      @include bp(md) {
        right: 54px;
        opacity: 0.3;
      }

      &:focus,
      &:hover {
        background: #fff url("#{$path-images}chev-arrow-teal-left.svg") no-repeat center center;
        background-size: contain;
        cursor: pointer;
      }
    }

    &.next {
      background: #fff url("#{$path-images}chev-arrow-charcoal-right.svg") no-repeat center center;
      background-size: contain;
      right: 0;
      @include bp(md) {
        right: 20px;
        opacity: 0.3;
      }

      &:focus,
      &:hover {
        background: #fff url("#{$path-images}chev-arrow-teal-right.svg") no-repeat center center;
        background-size: contain;
        cursor: pointer;
      }
    }
  }
}
