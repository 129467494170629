.l-support {
  //background: repeating-linear-gradient( 135deg, #ffffff, #ffffff 4px, #fbfbfb 4px, #fbfbfb 23px);
  background: url("../images/lines_flip.png") #efeee9 repeat;
  background-size: cover;

  .field-name-field-support-image {
    padding: 14px;
    background-color: #FFF;
    border: 1px solid #D8D8D8;
    margin-bottom: 45px;
    max-width: 340px;
    margin: -90px auto 40px;
  }

  .field-name-field-support-subheading {
    font-size: 1.75rem;
    line-height: 36px;
    margin: 0 0 em(10px);
    font-weight: 700;
    font-family: $roboto-slab;
    text-align: center;
    margin: 0;
    @include bp(sm) {
      font-size: 1.5rem;
      line-height: 32px;
      margin: 0;
    }
  }

  .dot-dividers {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    color: $teal;
    padding-bottom: 10px;
  }

  .field-name-field-support-copy {
    text-align: center;
    padding-bottom: 20px;
    @include bp(md) {
      width: 100%;
      max-width: 771px;
      margin: 0 auto;
    }
  }

  &-boundary {
    padding: 155px 15px 100px;
  }

  &-stage {
    background-color: #FCFCFC;
    border: 1px solid $teal;
    //padding-left: 20px;
    //padding-right: 20px;
    padding-left: 11px;
    padding-right: 11px;
    @include bp(md) {
      width: 100%;
      max-width: 1170px;
      margin: 0 auto;
    }
  }

  .support-learn-more {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.063rem;
    text-align: center;
    padding-bottom: 10px;
    max-width: 277px;
    margin: 0 auto 10px;
    @include bp(md) {
      max-width: none;
    }

    .l-arrow-extendable {
      margin-top: 10px;
    }

    a {
      &:hover {
        text-decoration: none;
        @include bp(md) {
          .arrow-extendable {
            @include animation(arrow-rise .3s);
            @include animation-fill-mode(forwards);
            @include animation-timing-function(ease-out);
          }
        }
      }
    }
  }

  .field-name-field-callout-circle-links {
    text-align: center;
    padding-bottom: 46px;
    @include bp(md) {
      max-width: 789px;
      margin: 0 auto;
    }

    .field-items {
      @include display(flex);
      @include justify-content(space-between);
      @include flex-wrap(wrap);
    }

    a {
      background-color: $teal;
      color: #fff;
      font-weight: 700;
      font-size: 0.813rem;
      line-height: 18px;
      text-transform: uppercase;
      @include bp(md) {
        // padding-top: 100px;
        // padding-bottom: 100px;
        width: 120px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .field-item {
      height: 150px;
      width: 150px;
      padding: 5px;
      margin-bottom: 15px;
      @include display(flex);
      @include align-items(center);
      @include justify-content(center);
      background: url("#{$path-images}circle-stroke.svg") no-repeat;
      background-size: contain;

      &:hover {
        background: url("#{$path-images}circle-stroke-hover.svg") no-repeat;
        background-size: contain;

        a {
          background-color: $blue;
        }
      }
      @include bp(xs3) {
        height: 200px;
        width: 200px;
        margin-bottom: 30px;
      }
    }
  }
}
