.l-mailing-list {
  padding-left: 34px;
  padding-right: 34px;
  margin-top: 53px;
  @include bp(md) {
    max-width: 771px;
    margin: 55px auto 0;
  }

  hr {
    margin-top: 0;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $charcoal;
    @include flex(1);
  }
}

.mailing-list-heading {
  @include display(flex);
  @include align-items(center);
}

.field-name-field-mailing-list-subheading {
  font-family: $roboto-slab;
  font-weight: 700;
  font-size: 1.75rem;
  @include flex(2);
  text-align: center;
  line-height: 37px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 20px;
  @include bp(md) {
    font-size: 1.5rem;
    @include flex(1);
    padding-bottom: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.field-name-field-mailing-list-content {
  text-align: center;
}

.mailing-button {
  background-color: $red;
  color: #FFF;
  font-weight: 700;
  font-size: 0.8125rem;
  padding: 9px 9px 11px;
  display: block;
  max-width: 227px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $red;
  @include swipe-fill-right(#FFF, $red);
  @include bp(md) {
    margin-top: 27px;
    line-height: 37px;
  }
}
