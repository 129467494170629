.l-connect {
  &-stage {
    padding-left: 34px;
    padding-right: 34px;
    margin-top: -200px;
    @include bp(md) {
      max-width: 771px;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
      margin-top: -155px;
    }
  }

  &-boundary {
    border-top: 14px solid #E0DBCA;
    //background: repeating-linear-gradient(45deg, #f4f3ef, #f4f3ef 4px, #efeee9 4px, #efeee9 23px);
    background: url("../images/lines.png") #efeee9 repeat;
    background-size: cover;
    padding-bottom: 100px;
    margin-top: 140px;
    @include bp(md) {
      margin-top: 90px;
    }

    .l-connect-social {
      .icon-twitter {
        width: 37px;
        height: 30px;
        @include bp(md) {
          width: 25px;
          height: 20px;
        }

        &:hover:before {
          background-size: contain;
        }

        &:before {
          width: 37px;
          height: 30px;
          background-size: contain;
          background: url("#{$path-images}icon-twitter-charcoal.svg") no-repeat;
          @include bp(md) {
            width: 25px;
            height: 20px;
          }
        }
      }

      .icon-facebook {
        width: 15px;
        height: 30px;
        @include bp(md) {
          width: 10px;
          height: 20px;
        }

        &:hover:before {
          background-size: contain;
        }

        &:before {
          width: 15px;
          height: 30px;
          background-size: contain;
          background: url("#{$path-images}icon-fb-charcoal.svg") no-repeat;
          @include bp(md) {
            width: 10px;
            height: 20px;
          }
        }
      }

      .icon-instagram {
        width: 30px;
        height: 30px;
        @include bp(md) {
          width: 20px;
          height: 20px;
        }

        &:hover:before {
          background-size: contain;
        }

        &:before {
          width: 30px;
          height: 30px;
          background-size: contain;
          background: url("#{$path-images}icon-instagram-charcoal.svg") no-repeat;
          @include bp(md) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &-header-boundary {
    @include display(flex);
    @include align-items(center);

    hr {
      margin-top: 0;
      width: 100%;
      height: 1px;
      border: 0;
      background-color: $charcoal;
      @include flex(1);
    }
  }

  &-social {
    @include display(flex);
    @include justify-content(center);
    padding-bottom: 33px;

    a {
      margin-right: 45px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-heading {
    font-family: $roboto-slab;
    font-weight: 700;
    font-size: 1.75rem;
    @include flex(2);
    text-align: center;
    line-height: 37px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 20px;
  }

  .l-twitter {
    width: 100%;
    max-width: 308px;
    height: 308px;
    background-color: #002F59;
    margin: 0 auto;
    @include bp(md) {
      width: 245px;
      height: 244px;
    }

    &-boundary {
      width: 100%;
      height: 100%;
      padding: 40px 30px;
      @include bp(md) {
        padding: 20px;
      }
    }

    > a {
      &:hover {
        .twitter-icon {
          background: url("#{$path-images}icon-twitter-hover.svg") no-repeat;
          background-size: contain;
        }
      }
    }

    .twitter-icon {
      width: 28px;
      height: 23px;
      background: url("#{$path-images}icon-twitter.svg") no-repeat;
      background-size: contain;
      margin-bottom: 41px;
    }

    a {
      color: #FFF;
    }
  }

  .l-instagram {
    width: 100%;
    max-width: 308px;
    height: 308px;
    margin: 0 auto;
    @include bp(md) {
      width: 245px;
      height: 244px;
    }

    .instagram-icon {
      width: 30px;
      height: 30px;
      background: url("#{$path-images}icon-instagram.svg") no-repeat;
      background-size: contain;
      position: absolute;
      top: 20px;
      left: 20px;
      @include bp(md) {
        width: 23px;
        height: 23px;
      }
    }

    img {
      width: 100%;
    }

    > a {
      &:hover {
        .instagram-icon {
          background: url("#{$path-images}icon-instagram-hover.svg") no-repeat;
          background-size: contain;
        }
      }
    }

    &-boundary {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

.l-homepage-twitter {
  padding-bottom: 22px;
  margin-right: 15px;
  @include bp(md) {
    padding-bottom: 0;
    padding-right: 17px;
  }
}

.l-homepage-instagram {
  .view-content {
    @include bp(md) {
       @include display(flex);
    }
  }

  .views-row {
    padding-bottom: 22px;
    @include bp(md) {
      padding-bottom: 0;
      padding-right: 17px;
    }

    &:last-child {
      padding-bottom: 0;
      display: none;
      @include bp(sm) {
        display: none;
      }
      @include bp(md) {
        padding-right: 0;
        display: block;
      }
    }
  }
}

.l-social-media-blocks {
  @include bp(sm) {
     @include display(flex);
    flex-direction: row;
    justify-content: center;
  }
  @include bp(md) {
     @include display(flex);
  }
}
