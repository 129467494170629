.l-arrow-extendable {
  height: 10px;
  margin: 0 auto;
  margin-top: 5px;

  @include bp(md) {
    padding-left: 49%;
  }

  &--inline {
    display: inline-block;
    margin-left: 6px;
    height: 6px;

    @include bp(md) {
      padding-left: 0;
    }
  }

  .arrow-extendable {
    position: relative;
    display: block;
    width: 23px;
    height: 1px;
    background: #FFF;
    margin: 0 auto;

    &.teal {
      background: $teal;

      &:after {
        background: url(#{$path-images}chev-arrow-teal.svg) no-repeat;
      }
    }

    @include bp(md) {
      margin: 0;
      @include transition(.3s ease);
      @include animation(arrow-fall .3s);
      @include animation-fill-mode(forwards);
    }

    &:after {
      content: '';
      width: 5px;
      height: 10px;
      display: block;
      background: url(#{$path-images}chev-arrow-white.svg) no-repeat;
      background-size: contain;
      position: absolute;
      right: -1px;
      top: -4px;
    }
  }
}
